import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import colors from 'style/colors';
import { mq, baseTransitionSecond } from 'style/variables';
import { Arrow } from 'components/atoms/arrow';
import arrowImageBlack from 'assets/images/icons/icon-arrow-link.svg';
import arrowImageWhite from 'assets/images/icons/icon-arrow-link-white.svg';

type ButtonProps = {
  href?: string;
  to?: string;
  blank?: boolean;
  type?: 'fill' | 'stroke';
};

const Container = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border: 1px solid ${colors.black01};
  font-size: 14px;
  line-height: 1.48em;
  width: 256px;
  ${mq.onlypc} {
    transition: color ${baseTransitionSecond}s ease, background ${baseTransitionSecond}s ease;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: ${colors.black01};
      transform: scaleX(0);
      transform-origin: right;
      transition: transform ${baseTransitionSecond}s ease;
      will-change: transform;
    }
    &:hover {
      color: ${colors.white01};
      &::before {
        transform: scaleX(1);
        transform-origin: left;
      }
      > span {
        background: bottom / contain no-repeat url(${arrowImageWhite});
      }
    }
  }
  ${props =>
    props.type === 'fill' &&
    css`
      color: ${colors.white01};
      background-color: ${colors.black01};
      ${mq.onlypc} {
        &::before {
          background-color: ${colors.white01};
        }
        &:hover {
          color: ${colors.black01};
          > span {
            background: bottom / contain no-repeat url(${arrowImageBlack});
          }
        }
        &:link,
        &:visited,
        &:focus,
        &:active {
          color: ${colors.white01};
          > span {
            background: bottom / contain no-repeat url(${arrowImageWhite});
          }
          &:hover {
            color: ${colors.black01};
            > span {
              background: bottom / contain no-repeat url(${arrowImageBlack});
            }
          }
        }
      }
      ${mq.onlysp} {
        &:link,
        &:visited,
        &:focus,
        &:active {
          color: ${colors.white01};
          > span {
            background: bottom / contain no-repeat url(${arrowImageWhite});
          }
        }
      }
    `};
`;

const ArrowWrapper = styled(Arrow)`
  width: 24px;
  height: 24px;
  right: 16px;
  position: absolute;
`;

const Text = styled.div`
  position: relative;
`;

export const Button: React.FC<ButtonProps> = props => {
  if (props.to) {
    const ContainerLink = Container.withComponent(Link);
    return (
      <ContainerLink {...props} to={props.to}>
        <Text>{props.children}</Text>
        <ArrowWrapper hoverAnime color={props.type === 'fill' ? 'white' : 'black'} />
      </ContainerLink>
    );
  } else {
    return (
      <Container
        {...props}
        href={props.href}
        target={props.blank ? '_blank' : '_self'}
        rel={props.blank ? 'noopener noreferrer' : undefined}
      >
        <Text>{props.children}</Text>
        <ArrowWrapper hoverAnime color={props.type === 'fill' ? 'white' : 'black'} />
      </Container>
    );
  }
};
