import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Arrow2 } from 'components/atoms/arrow2';
import { EnHeading } from 'components/atoms/enHeading';
import { Link } from 'gatsby';
import React from 'react';
import { baseTransitionSecond, mq } from 'style/variables';
import { ImgMask } from '../imgMask/index';

type StudioCardProps = {
  to?: string;
  imgpath: string;
  place: string;
  type?: 'about';
};

const Container = styled(Link)`
  display: block;
  position: relative;
`;

const ImageWrapper = styled.div<Pick<StudioCardProps, 'type'>>`
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    padding-top: ${(321 / 428) * 100}%;

    ${({ type }) =>
      type === 'about'
        ? css`
            padding-top: ${(184 / 275) * 100}%;
          `
        : null}
  }
  & > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    & > div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
`;

const Place = styled.div<Pick<StudioCardProps, 'type'>>`
  position: relative;
  padding-top: 13px;
  ${({ type }) =>
    type === 'about'
      ? css`
          padding-top: 13px;
          padding-bottom: 0;
        `
      : null}

  ${mq.onlypc} {
    & > span {
      position: relative;
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      transform: scaleX(0);
      transform-origin: left;
      opacity: 0.3;
    }

    a:hover > & {
      &::before {
        transform: scaleX(1);
        opacity: 0;
        transition: transform ${baseTransitionSecond}s linear,
          opacity ${baseTransitionSecond}s linear ${baseTransitionSecond}s;
      }
    }
  }
  ${mq.onlysp} {
    padding-top: 28px;
    padding-bottom: 43px;
    ${({ type }) =>
      type === 'about'
        ? css`
            padding-top: 15px;
            padding-bottom: 0;
          `
        : null}
  }
`;

export const StudioCard: React.FC<StudioCardProps> = props => {
  const TagName = Container.withComponent(props.to ? Link : 'div');
  return (
    <TagName {...(props.to ? { to: props.to } : {})}>
      <ImageWrapper type={props.type}>
        <ImgMask filename={props.imgpath} alt={props.place} isCover></ImgMask>
        {props.to && <Arrow2 />}
      </ImageWrapper>
      <Place type={props.type}>
        <EnHeading size={'xsmall'}>{props.place}</EnHeading>
      </Place>
    </TagName>
  );
};
